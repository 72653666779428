@font-face {
  font-family: Roboto;
  src: url("./asset/fonts/Roboto-Regular.ttf");
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 1.5em;
}

body {
  position: relative;
  z-index: 0;
  min-height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  font-family: "Roboto","Lato",'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.display-none{
  display: none;
}

.flex{
  display: flex;
}

.flex-column{
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-end{
  justify-content: flex-end;
}

.justify-center{
  justify-content: center;
}

.space-around{
  justify-content: space-around;
}

.space-between{
  justify-content: space-between;
}

.align-items-center{
  align-items: center;
}
.text-align-center{
  text-align: center;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 14px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #b5b5b5;
}

.container{
  margin: 0 auto;
  max-width: 1200px;
}
